import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Page = () => (
  <Layout>
    <SEO title="Coming Soon" />
    <h1>Contact Page Coming Soon</h1>
    
  </Layout>
)

export default Page
